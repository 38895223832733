import {
	StateAuth,
	StateAuthMeta,
} from "@kunepro/ui-types";
import {
	Action,
	createReducer,
	on,
} from "@ngrx/store";
import * as ActionsAuth from "./auth.actions";

export const initialState: StateAuth = {
	meta: {
		...new StateAuthMeta(),
	},
};

const reducer = createReducer(
	initialState,
	on(
		ActionsAuth.Authenticated,
		(state, { user }): StateAuth => ({
			...state,
			user,
			meta: {
				...state.meta,
				isSignedIn: true,
			},
		}),
	),
	on(
		ActionsAuth.NotAuthenticated,
		(state): StateAuth => ({
			...state,
			user: null,
			meta: {
				...state.meta,
				isSignedIn: false,
			},
		}),
	),
	on(
		ActionsAuth.SignOut,
		(): StateAuth => ({ ...initialState }),
	),
);

export function authReducer(
	state: StateAuth | undefined,
	action: Action,
) {
	return reducer(
		state,
		action,
	);
}

import { OverlayContainer } from "@angular/cdk/overlay";
import { Injectable } from "@angular/core";
import { MaterialTheme } from "@kunepro/ui-types";
import {
	BehaviorSubject,
	Observable,
} from "rxjs";
import { scan } from "rxjs/operators";
import { IndexDBService } from "../index-db/index-db.service";

interface ScanAccumulator {
	readonly previous: MaterialTheme | undefined;
	readonly current: MaterialTheme;
}

const seed: ScanAccumulator = {
	previous: undefined,
	current:  MaterialTheme.Light,
};

@Injectable()
export class MaterialService {
	private readonly _theme$                          = new BehaviorSubject<MaterialTheme>(MaterialTheme.Light);
	public readonly theme$: Observable<MaterialTheme> = this._theme$.asObservable();

	constructor(
		private readonly overlayContainer: OverlayContainer,
		private readonly indexDB: IndexDBService,
	) {
		this.themeChangesSubscription();
	}

	public changeTheme(theme: MaterialTheme): void {
		this._theme$.next(theme);
		this.indexDB.config.putTheme(theme);
	}

	private themeChangesSubscription(): void {
		this.theme$
			.pipe(
				scan(
					(acc: ScanAccumulator, current: MaterialTheme) => ({
						previous: acc.current,
						current,
					}),
					seed,
				),
			)
			// tslint:disable-next-line:rxjs-no-ignored-subscription
			.subscribe(({ previous, current }) => {
				if (previous) {
					this.overlayContainer.getContainerElement().classList.remove(previous);
				}
				this.overlayContainer.getContainerElement().classList.add(current);
			});
	}
}

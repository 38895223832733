export const MLPUnicornsMares = [
	"Rarity",
	"Cinnamon Chai",
	"Clear Sky",
	"Fire Flare",
	"First Folio",
	"Fleur de Lis",
	"Lily Lace",
	"Luster Dawn",
	"Merry",
	"Mistmane",
	"Moon Dancer",
	"Sable Spirit",
	"Saffron Masala",
	"Sassy Saddles",
	"Snowfall Frost",
	"Starlight Glimmer",
	"Stellar Flare",
	"Sugar Belle",
	"Sunset Shimmer",
	"Tempest Shadow",
	"The Great and Powerful Trixie",
	"Twilight Sparkle",
	"Upper Crust",
	"Zesty Gourmand",
	"Twilight Velvet",
	"Cookie Crumbles",
	"Amethyst Star",
	"Apple Stars",
	"Banana Fluff",
	"Bittersweet",
	"Cherry Spices",
	"Cipher Splash",
	"Diamond Mint",
	"DJ Pon-3",
	"Electric Sky",
	"Fine Line",
	"Four Step",
	"Holly Dash",
	"Lemon Hearts",
	"Lemony Gem",
	"Lilac Hearts",
	"Lyra Heartstrings",
	"Minuette",
	"Old Money",
	"Peachy Pitt",
	"Pinny Lane",
	"Press Release",
	"Pretzel",
	"Royal Ribbon",
	"Sea Swirl",
	"Silver Spanner",
	"Sunshine Petals",
	"Swan Song",
	"Twinkleshine",
	"Apple Bumpkin",
	"Daisy",
	"Dane Tee Dove",
	"Lucy Packard",
	"Mare Do Well",
	"Pumpkin Cake",
	"Raven Inkwell",
	"Red Gala",
	"Rose",
	"Sapphire Shores",
	"Sweetie Belle",
	"Twilight Sparkle",
];

export const MLPUnicornsStallions = [
	"Royal Pin",
	"Rare Find",
	"Neon Lights",
	"Golden Gavel",
	"Magnet Bolt",
	"Night Light",
	"Trenderhoof",
	"Stygian",
	"Sunburst",
	"royal guards",
	"Professor Flintheart",
	"Prince Blueblood",
	"Party Favor",
	"Shining Armor",
	"King Sombra",
	"Star Swirl the Bearded",
	"Coriander Cumin",
	"Fancy Pants",
	"Doctor Horse",
	"Fashion Plate",
	"Dandy Grandeur",
	"Bracer Britches",
	"Bluenote",
	"Claude",
	"Chancellor Neighsay",
	"Hoo'Far",
	"Flim",
	"Firelight",
	"Flam",
	"Jet Set",
	"Jack Pot",
	"Joe",
	"Hoofdini",
	"Bright Bulb",
	"Blue Moon",
	"Hondo Flanks",
	"Bags Valet",
	"Comet Tail",
	"Dark Moon",
	"Savoir Fare",
	"Shooting Star",
	"Top Marks",
	"Discord",
	"Big McIntosh",
	"Vinny",
	"Whinnyfield",
	"Noteworthy",
	"Goldengrape",
	"Perfect Pace",
];

export const MLPUnicorns = MLPUnicornsMares.concat(MLPUnicornsStallions);
Object.freeze(MLPUnicorns);
Object.freeze(MLPUnicornsMares);
Object.freeze(MLPUnicornsStallions);

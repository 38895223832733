import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { URL } from "@kunepro/common";
import {
	FirebaseUserSerialiser,
	handleEffectError$,
} from "@kunepro/ui-core";
import { StoreFacade } from "@kunepro/ui-store";
import {
	RouteApp,
	StateRouter,
} from "@kunepro/ui-types";
import {
	Actions,
	createEffect,
	ofType,
	ROOT_EFFECTS_INIT,
} from "@ngrx/effects";
import {
	ROUTER_REQUEST,
	RouterRequestAction,
} from "@ngrx/router-store";
import { User as FirebaseUser } from "firebase";
import {
	from,
	of,
} from "rxjs";
import {
	concatMap,
	filter,
	map,
	switchMap,
	take,
	tap,
	withLatestFrom,
} from "rxjs/operators";
import { IndexDBService } from "../index-db/index-db.service";
import * as ActionsAuth from "./auth.actions";

const authenticationFragments: string[] = [
	RouteApp.SignIn,
	RouteApp.Register,
	RouteApp.RecoverPassword,
	RouteApp.ResetPassword,
];

const authenticationUrls: URL[] = authenticationFragments.map((fragment) => "/" + fragment);

@Injectable()
export class AuthEffects {

	public onVerifySession$ = createEffect(
		() => this.actions$.pipe(
			ofType(ROOT_EFFECTS_INIT),
			take(1),
			switchMap(() =>
				this.angularFireAuth.authState
					.pipe(
						map((authData: FirebaseUser | null) => {
							const serialiser = new FirebaseUserSerialiser();

							return authData
								? ActionsAuth.Authenticated({ user: serialiser.userFromFirebaseUser(authData) })
								: ActionsAuth.NotAuthenticated();
						}),
						handleEffectError$(ActionsAuth.AuthenticatedError()),
					),
			),
		),
		{ useEffectsErrorHandler: false },
	);

	// noinspection JSUnusedGlobalSymbols
	public onAuthenticatedReturnUserToScenario$ = createEffect(
		() => this.actions$.pipe(
			ofType(ActionsAuth.Authenticated),
			concatMap((action) => of(action)
				.pipe(
					withLatestFrom(
						this.storeFacade.router.fragments$,
						(a, fragments) => fragments,
					),
				),
			),
			filter((fragments: string[] = []) => authenticationFragments.includes(fragments[ 0 ])),
			switchMap(() =>
				this.storeFacade.indexDB.auth.postAuthenticateReturnURL$
					.pipe(take(1)),
			),
			tap((returnURL: URL | undefined) => {
				this.router.navigateByUrl(returnURL || "/");
			}),
		),
		{ dispatch: false },
	);

	public setPostAuthenticateReturnURL$ = createEffect(
		() => this.actions$.pipe(
			ofType(ROUTER_REQUEST),
			filter((action: RouterRequestAction<StateRouter>) => {
				const isCurrentRouteAboutAuthentication  = authenticationUrls.some((route) => route.startsWith(action.payload.event.url));
				const isPreviousRouteAboutAuthentication = authenticationFragments.includes(action.payload.routerState.fragments[ 0 ]);

				return isCurrentRouteAboutAuthentication && !isPreviousRouteAboutAuthentication;
			}),
			tap((action) => { this.indexDB.auth.putPostAuthenticateReturnURL(action.payload.routerState.url); }),
		),
		{ dispatch: false },
	);

	public onLogOut$ = createEffect(
		() => this.actions$.pipe(
			ofType(ActionsAuth.SignOut),
			switchMap(() => from(this.angularFireAuth.signOut())
				.pipe(
					map(() => ActionsAuth.SignOutSuccess()),
					handleEffectError$(ActionsAuth.SignOutError()),
				),
			),
		),
	);


	constructor(
		private readonly actions$: Actions,
		private readonly angularFireAuth: AngularFireAuth,
		private readonly indexDB: IndexDBService,
		private readonly storeFacade: StoreFacade,
		private readonly router: Router,
	) { }

}

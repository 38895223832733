export const MLPKirinMares = [
	"Autumn Blaze",
	"Fern Flare",
	"Rain Shine",
];

export const MLPKirinStallions: string[] = [];

export const MLPKirin = MLPKirinMares.concat(MLPKirinStallions);
Object.freeze(MLPKirin);
Object.freeze(MLPKirinMares);
Object.freeze(MLPKirinStallions);

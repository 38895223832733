import { MLPCrystalPonies } from "./mlp-crystal-ponies";
import { MLPEarthPonies } from "./mlp-earth-ponies";
import { MLPKirin } from "./mlp-kirin";
import { MLPPegasi } from "./mlp-pegasi";
import { MLPUnicorns } from "./mlp-unicorns";

export const MLPAlicornsMares = [
	"Princess Twilight Sparkle",
	"Princess Mi Amore Cadenza",
	"Princess Celestia",
	"Princess Luna",
	"Queen Sunset Shimmer",
];

export const MLPAlicornsStallions = [
	"King Dawn Shimmer",
];

export const MLPAlicorns = MLPAlicornsMares.concat(MLPAlicornsStallions);
export const MLPPonies   = MLPAlicorns.concat(
	MLPEarthPonies,
	MLPPegasi,
	MLPUnicorns,
	MLPCrystalPonies,
	MLPKirin,
);
Object.freeze(MLPAlicorns);
Object.freeze(MLPAlicornsMares);
Object.freeze(MLPAlicornsStallions);

import { Params } from "@angular/router";
import {
	QueryParamsKunepro,
	StateApp,
	StateRouter,
	StoreFeature,
} from "@kunepro/ui-types";
import { RouterReducerState } from "@ngrx/router-store";
import {
	createFeatureSelector,
	createSelector,
} from "@ngrx/store";

const selectRouterState = createFeatureSelector<StateApp, RouterReducerState<StateRouter> | undefined>(StoreFeature.Router);

export const selectRouterStateSerialisedState = createSelector(
	selectRouterState,
	(routerState: RouterReducerState<StateRouter> | undefined): StateRouter | undefined => routerState?.state,
);

export const selectUrl = createSelector(
	selectRouterStateSerialisedState,
	(routerState: StateRouter | undefined): string | undefined => routerState?.url,
);

export const selectFragments = createSelector(
	selectRouterStateSerialisedState,
	(routerState: StateRouter | undefined): string[] | undefined => routerState?.fragments,
);

export const selectParams = createSelector(
	selectRouterStateSerialisedState,
	(routerState: StateRouter | undefined): Params | undefined => routerState?.params,
);

export const selectQueryParams = createSelector(
	selectRouterStateSerialisedState,
	(routerState: StateRouter | undefined): QueryParamsKunepro | undefined => routerState && routerState.queryParams,
);

// export const selectAuxiliaryOutlets = createSelector(
// 	selectRouterStateSerialisedState,
// 	(routerState: StateRouter | undefined): AuxiliaryOutlets | undefined => routerState && routerState.auxiliaryOutlets,
// );
//
// export const selectSidePanel = createSelector(
// 	selectAuxiliaryOutlets,
// 	(auxiliaryOutlets: AuxiliaryOutlets | undefined): SidePanelID | undefined => auxiliaryOutlets && auxiliaryOutlets.sidePanel,
// );

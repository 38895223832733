/**
 * @param cookie The whole string with all cookies received with the request.
 * @param name   The name (ID) of the actual cookie.
 *
 * @return value The value of the cookie.
 */

export function getCookieValue(cookie: string, name: string): string | null {
	const pattern = `${ name }=([^;\\s]*)`;
	const result  = cookie.match(pattern);

	return result && result[ 1 ] || null;
}

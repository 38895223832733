import { StateUser } from "@kunepro/ui-types";
import {
	Action,
	createReducer,
	on,
} from "@ngrx/store";

import * as ActionsUser from "./user.actions";

export const initialState: StateUser = {
	name:       "",
	lastName:   "",
	loading:    false,
	isExisting: true,
};

const reducer = createReducer(
	initialState,
	on(
		ActionsUser.Get,
		(state): StateUser => ({
			...state,
			loading: true,
		}),
	),
	on(
		ActionsUser.GetSuccess,
		(state, { name, lastName }): StateUser => ({
			...state,
			loading:    false,
			isExisting: true,
			name,
			lastName,
		}),
	),
	on(
		ActionsUser.GetNotFound,
		(state): StateUser => ({
			...state,
			loading:    false,
			isExisting: false,
		}),
	),
	on(
		ActionsUser.GetError,
		(state): StateUser => ({
			...state,
			loading: false,
		}),
	),
);

export function userReducer(
	state: StateUser | undefined,
	action: Action,
) {
	return reducer(
		state,
		action,
	);
}

export const MockedVerbs: string[] = [
	"added",
	"removed",
	"destroyed",
	"purged",
	"climbed",
	"rocked",
	"created",
	"insulted",
	"ponied",
	"undid",
];

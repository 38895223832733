import { Routes } from "@angular/router";
import { RouteApp } from "@kunepro/ui-types";
import { IsSignedOffGuard } from "./shared/guards/is-signed-off.guard";

export const AppRoutes: Routes = [
	{
		path:         RouteApp.SignIn,
		canActivate:  [ IsSignedOffGuard ],
		canLoad:      [ IsSignedOffGuard ],
		loadChildren: () => import("./routes/sign-in/sign-in.module")
			.then((m) => m.SignInModule),
	},
	{
		path:         RouteApp.Register,
		canActivate:  [ IsSignedOffGuard ],
		canLoad:      [ IsSignedOffGuard ],
		loadChildren: () => import("./routes/register/register.module")
			.then((m) => m.RegisterModule),
	},
	{
		path:         RouteApp.ResetPassword,
		canActivate:  [ IsSignedOffGuard ],
		canLoad:      [ IsSignedOffGuard ],
		loadChildren: () => import("./routes/reset-password/reset-password.module")
			.then((m) => m.ResetPasswordModule),
	},
	{
		path:         RouteApp.RecoverPassword,
		canActivate:  [ IsSignedOffGuard ],
		canLoad:      [ IsSignedOffGuard ],
		loadChildren: () => import("./routes/recover-password/recover-password.module")
			.then((m) => m.RecoverPasswordModule),
	},
	{
		path:         RouteApp.UserProfile,
		loadChildren: () => import("./routes/user-profile/user-profile.module")
			.then((m) => m.UserProfileModule),
	},
	{
		path:         RouteApp.Examples,
		loadChildren: () => import("./routes/examples/examples.module")
			.then((m) => m.ExamplesModule),
		// canActivate:  [ AuthGuard ],
		// pathMatch: "full",
		// TODO make this route be loaded only in non-production, so it won't get build and published (to test)
	},
	{
		path:       "**",
		redirectTo: "",
	},
];

import { Injectable } from "@angular/core";
import {
	Actions,
	createEffect,
	ofType,
} from "@ngrx/effects";
import { of } from "rxjs";
import {
	catchError,
	map,
	switchMap,
} from "rxjs/operators";
import * as ActionsUser from "./user.actions";
import { UserService } from "./user.service";

@Injectable()
export class UserEffects {

	public getUserInformation$ = createEffect(
		() => this.actions$.pipe(
			ofType(ActionsUser.Get),
			switchMap(({ id }) =>
				this.userService.getUser$(id).pipe(
					map((data) => data
						? ActionsUser.GetSuccess(data)
						: ActionsUser.GetNotFound()),
					catchError(() => of(ActionsUser.GetError())),
				),
			),
		),
	);

	constructor(
		private readonly actions$: Actions,
		private readonly userService: UserService,
	) {}
}

import {
	AbstractControl,
	ValidatorFn,
} from "@angular/forms";
import { Dictionary } from "@kunepro/common";
// noinspection ES6PreferShortImport
import { ValidationErrorPasswordsDoNotMatch } from "../types/validation-error-passwords-do-not-match";

// tslint:disable-next-line:no-any
interface ControlValueWithPasswordConfirmation extends Dictionary<any> {
	readonly password: string;
	readonly passwordConfirmation: string;
}

interface AbstractControlWithPasswordConfirmation extends AbstractControl {
	readonly value: ControlValueWithPasswordConfirmation;
}

export class ValidatorsFEL {
	public static passwordsMatch(): ValidatorFn {
		return (control: AbstractControlWithPasswordConfirmation): ValidationErrorPasswordsDoNotMatch | null => {
			const password        = control.value.password;
			const passwordToMatch = control.value.passwordConfirmation;

			return password !== passwordToMatch ? new ValidationErrorPasswordsDoNotMatch() : null;
		};
	}

}

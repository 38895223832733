import { Injectable } from "@angular/core";
import {
	MaterialTheme,
	PropsFirebaseSignIn,
	PropsUserGet,
	StateApp,
} from "@kunepro/ui-types";
import {
	select,
	Store,
} from "@ngrx/store";
import * as  ActionsAuth from "./auth/auth.actions";
import * as  fromAuth from "./auth/auth.selectors";
import { IndexDBService } from "./index-db/index-db.service";
import { MaterialService } from "./material/material.service";
import { ConnectivityService } from "./network/connectivity.service";
import * as  fromRouter from "./router/router.selectors";
import * as  ActionsUser from "./user/user.actions";

@Injectable()
export class StoreFacade {
	public readonly auth = {
		user$:       this.store$.pipe(select(fromAuth.selectUser)),
		isSignedIn$: this.store$.pipe(select(fromAuth.selectIsSignedIn)),

		userSignIn:     (props: PropsFirebaseSignIn) => this.store$.dispatch(ActionsAuth.UserSignIn(props)),
		logOff:         () => this.store$.dispatch(ActionsAuth.SignOut()),
		// tslint:disable-next-line
		verifyEmail:    () => {},
		// tslint:disable-next-line
		changePassword: () => {},
	};

	public readonly user = {
		get: (payload: PropsUserGet) => this.store$.dispatch(ActionsUser.Get(payload)),
	};

	public readonly router = {
		url$:         this.store$.pipe(select(fromRouter.selectUrl)),
		fragments$:   this.store$.pipe(select(fromRouter.selectFragments)),
		params$:      this.store$.pipe(select(fromRouter.selectParams)),
		queryParams$: this.store$.pipe(select(fromRouter.selectQueryParams)),
		// sidePanel$: this.store$.pipe(select(fromRouter.selectSidePanel)),
	};

	public readonly material = {
		theme$: this.materialService.theme$,

		setTheme: (theme: MaterialTheme) => this.materialService.changeTheme(theme),
	};

	public readonly network = {
		connected$: this.connectivityService.connected$,
	};

	constructor(
		private readonly store$: Store<StateApp>,
		private readonly materialService: MaterialService,
		private readonly connectivityService: ConnectivityService,
		public readonly indexDB: IndexDBService,
	) {}
}

import { Injectable } from "@angular/core";
import { URL } from "@kunepro/common";
import { IndexDBServiceGeneric } from "@kunepro/front-end-library";
import {
	IDBAuthKey,
	IDBConfigKey,
	IDBStoreName,
	MaterialTheme,
} from "@kunepro/ui-types";

const KUNEPRO_DATABASE = "Kunepro";

@Injectable()
export class IndexDBService extends IndexDBServiceGeneric {

	public readonly config = {
		theme$: this.getProperty$<MaterialTheme>(
			IDBStoreName.Configuration,
			IDBConfigKey.Theme,
		),

		putTheme: (theme: MaterialTheme) => this.putProperty<MaterialTheme>(
			IDBStoreName.Configuration,
			theme,
			IDBConfigKey.Theme,
		),
	};

	public readonly auth = {
		postAuthenticateReturnURL$: this.getProperty$<URL>(
			IDBStoreName.Auth,
			IDBAuthKey.PostAuthenticateReturnURL,
		),

		putPostAuthenticateReturnURL: (url: URL) => this.putProperty<URL>(
			IDBStoreName.Auth,
			url,
			IDBAuthKey.PostAuthenticateReturnURL,
		),
	};

	public init(): void {
		const req: IDBOpenDBRequest = this.window.indexedDB.open(
			KUNEPRO_DATABASE,
			1,
		);

		req.onupgradeneeded = (evt: IDBVersionChangeEvent) => {
			const currentTarget = evt.currentTarget as IDBOpenDBRequest;

			if (evt.oldVersion === 0) {
				currentTarget.result.createObjectStore(
					IDBStoreName.Auth,
				);
				currentTarget.result.createObjectStore(
					IDBStoreName.Configuration,
				);
			}
		};

		req.onsuccess = () => {
			this.db$.next(req.result);
		};

		req.onerror = this.logOutError;
	}
}

export function initialiseIDB(
	idbService: IndexDBService,
) {
	return (): void => {
		idbService.init();
	};
}

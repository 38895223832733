import {
	AfterViewChecked,
	Directive,
	ElementRef,
	Injectable,
	Input,
	NgModule,
	Pipe,
	PipeTransform,
} from "@angular/core";
import {
	LangChangeEvent,
	TranslateService,
} from "@ngx-translate/core";
import {
	BehaviorSubject,
	Observable,
	of,
	Subject,
} from "rxjs";
// tslint:disable:rxjs-finnish
// tslint:disable:rxjs-no-exposed-subjects
// tslint:disable:directive-selector
// tslint:disable:no-any
// noinspection JSUnusedLocalSymbols

export const TRANSLATED_STRING = "i18n";

@Injectable()
export class TranslateServiceMock {
	public readonly onLangChangeSubject: Subject<LangChangeEvent> = new Subject();
	public readonly onTranslationChangeSubject: Subject<string>   = new Subject();
	public readonly onDefaultLangChangeSubject: Subject<string>   = new Subject();
	public readonly isLoadedSubject: BehaviorSubject<boolean>     = new BehaviorSubject<boolean>(true);

	public readonly onLangChange: Observable<LangChangeEvent> = this.onLangChangeSubject.asObservable();
	public readonly onTranslationChange: Observable<string>   = this.onTranslationChangeSubject.asObservable();
	public readonly onDefaultLangChange: Observable<string>   = this.onDefaultLangChangeSubject.asObservable();
	public readonly isLoaded: Observable<boolean>             = this.isLoadedSubject.asObservable();

	public currentLang: string = "";

	public languages: string[] = [ "de" ];

	public get(content: string): Observable<string> {
		return of(TRANSLATED_STRING + content);
	}

	public use(lang: string): void {
		this.currentLang = lang;
		this.onLangChangeSubject.next({ lang } as LangChangeEvent);
	}

	public addLangs(langs: string[]): void {
		this.languages = [
			...this.languages,
			...langs,
		];
	}

	public getBrowserLang(): string {
		return "";
	}

	public getLangs(): string[] {
		return this.languages;
	}

	public getTranslation(): Observable<object> {
		return of({});
	}

	// noinspection JSUnusedLocalSymbols
	public instant(
		key: string | string[],
		interpolateParams?: object,
	): string {
		return TRANSLATED_STRING + key.toString();
	}

	public setDefaultLang(lang: string): void {
		this.onDefaultLangChangeSubject.next(lang);
	}
}

@Pipe({ name: "translate" })
export class TranslateMockPipe implements PipeTransform {
	transform(text: string | string[]): string[] {
		return !text ? [ TRANSLATED_STRING ] : [ `${ text }-${ TRANSLATED_STRING }` ];
	}
}

@Directive({
	selector: "[translate]",
})
export class TranslateMockDirective implements AfterViewChecked {
	@Input()
	translateParams: any;

	constructor(private readonly _element: ElementRef) {}

	ngAfterViewChecked(): void {
		this._element.nativeElement.innerText += TRANSLATED_STRING;
	}
}

@NgModule({
	declarations: [
		TranslateMockPipe,
		TranslateMockDirective,
	],
	exports:      [
		TranslateMockPipe,
		TranslateMockDirective,
	],
	providers:    [
		{
			provide:  TranslateService,
			useClass: TranslateServiceMock,
		},
	],
})
export class TranslateMockModule {}

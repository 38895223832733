import {
	Inject,
	Injectable,
} from "@angular/core";
import {
	Observable,
	ReplaySubject,
} from "rxjs";
import {
	switchMap,
	take,
} from "rxjs/operators";
// noinspection ES6PreferShortImport
import { WINDOW } from "../injection-tokens/window.token";
// noinspection ES6PreferShortImport
import { IDBMode } from "../types/idb-mode";

type IDBStoreName = string;

@Injectable()
export class IndexDBServiceGeneric {
	protected readonly db$ = new ReplaySubject<IDBDatabase>(1);

	constructor(@Inject(WINDOW) protected readonly window: Window) {}

	protected getProperty$<T>(storeName: IDBStoreName, query: IDBValidKey | IDBKeyRange): Observable<T | undefined> {
		return this.db$
			.pipe(
				take(1),
				switchMap((db) => {
					return new Promise<T | undefined>((resolve, reject) => {
						const request: IDBRequest = db.transaction(
							storeName,
							IDBMode.ReadOnly,
						)
							.objectStore(storeName)
							.get(query);

						request.onsuccess = () => resolve(request.result);

						// @ts-ignore
						request.onerror = (e: Event) => reject((e.target.error));
					});
				}),
			);
	}

	protected putProperty<T>(storeName: IDBStoreName, value: T, key?: IDBValidKey): void {
		this.db$
			.pipe(take(1))
			// tslint:disable-next-line:rxjs-no-ignored-subscription
			.subscribe((db) => {
				const request: IDBRequest = db.transaction(
					storeName,
					IDBMode.ReadWrite,
				)
					.objectStore(storeName)
					.put(
						value,
						key,
					);

				request.onerror = this.logOutError;
			});
	}

	protected logOutError(event: Event) {
		// tslint:disable-next-line:no-console
		console.error(
			"IndexDB: ",
			// @ts-ignore
			event.target.error,
		);
	}
}

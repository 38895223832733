import {
	Inject,
	Injectable,
} from "@angular/core";
import { WINDOW } from "@kunepro/front-end-library";
import {
	BehaviorSubject,
	fromEvent,
	Observable,
} from "rxjs";

@Injectable()
export class ConnectivityService {
	private readonly _connected$                    = new BehaviorSubject<boolean>(true);
	public readonly connected$: Observable<boolean> = this._connected$.asObservable();

	constructor(@Inject(WINDOW) private readonly window: Window) {
		this.onConnectSubscription();
		this.onDisconnectSubscription();
	}

	private onConnectSubscription(): void {
		fromEvent(
			this.window,
			"online",
		)
			// tslint:disable-next-line:rxjs-no-ignored-subscription
			.subscribe(
				() => {
					this._connected$.next(true);
				},
			);
	}

	private onDisconnectSubscription(): void {
		fromEvent(
			this.window,
			"offline",
		)
			// tslint:disable-next-line:rxjs-no-ignored-subscription
			.subscribe(
				() => {
					this._connected$.next(false);
				},
			);
	}
}

import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireFunctionsModule } from "@angular/fire/functions";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/snack-bar";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { WindowProviders } from "@kunepro/front-end-library";
import {
	FormAuthService,
	SentryProvider,
} from "@kunepro/ui-core";
import { StoreFacade } from "@kunepro/ui-store";
import { ReactiveComponentModule } from "@ngrx/component";
import { environment } from "../environments/environment";
import { AppComponent } from "./app.component";
import { AppRoutes } from "./app.routes";
import { AppHeaderProfileCardViewComponent } from "./core/components/app-header/app-header-profile-card-view/app-header-profile-card-view.component";
import { AppHeaderComponent } from "./core/components/app-header/app-header.component";
import { SharedModule } from "./shared/shared.module";
import { AppStoreModule } from "./store/store.module";

const Material = [
	CommonModule,
	RouterModule,
	MatDividerModule,
	MatIconModule,
	MatMenuModule,
	MatToolbarModule,
	MatCardModule,
	MatButtonModule,
];

@NgModule({
	declarations: [
		AppComponent,
		AppHeaderComponent,
		AppHeaderProfileCardViewComponent,
	],
	imports:      [
		CommonModule,
		BrowserModule,
		HttpClientModule,
		BrowserAnimationsModule,
		SharedModule,
		RouterModule.forRoot(
			AppRoutes,
			{
				initialNavigation: "enabled",

				// KTC Router Debugging
				// enableTracing: true,
			},
		),

		// Firebase
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireAuthModule,
		AngularFireFunctionsModule,
		AngularFirestoreModule,
		AngularFireDatabaseModule,
		AngularFireStorageModule,

		// NgRx
		AppStoreModule,

		// Material
		...Material,
		ReactiveComponentModule,
	],
	providers:    [
		StoreFacade,
		WindowProviders,
		SentryProvider,
		{
			provide:  MAT_SNACK_BAR_DEFAULT_OPTIONS,
			useValue: {
				duration:           4000,
				horizontalPosition: "right",
				verticalPosition:   "top",
			},
		},
		FormAuthService,
	],
	bootstrap:    [ AppComponent ],
})
export class AppModule {}

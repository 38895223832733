<mat-toolbar role="heading">
	<a
		id="app-logo"
		routerLink="/"
	>
		<img
			alt="Kunepro logo"
			src="../../../../assets/images/placeholder_logo.png"
		>
		<span id="app-title">Kunepro</span>
	</a>
	<div class="align--right flex-space-between">

		<div *ngIf="!(connected$ | ngrxPush)">
			<mat-icon color="warn">signal_wifi_off</mat-icon>
			<span class="cdk-visually-hidden">Device is not connected to the internet.</span>
		</div>

		<ng-container *ngIf="isSignedIn$ | ngrxPush; else signInButton">
			<kun-app-header-profile-card-view
				(signOut)="onSignOut()"
				[user]="user$ | ngrxPush"
			></kun-app-header-profile-card-view>
		</ng-container>

	</div>
</mat-toolbar>

<ng-template #signInButton>
	<a
		[routerLink]="signInRoute"
		color="primary"
		mat-flat-button
	>Sign In</a>
</ng-template>

import { Environment } from "@kunepro/ui-types";

export const environment: Environment = {
	production: false,
	firebase:   {
		apiKey:            "AIzaSyAI8nIPpQARV2Oi7mEpydbeYf2eodOR6lc",
		authDomain:        "kunepro-stage.firebaseapp.com",
		databaseURL:       "https://kunepro-stage.firebaseio.com",
		projectId:         "kunepro-stage",
		storageBucket:     "kunepro-stage.appspot.com",
		messagingSenderId: "865361685643",
	},
};

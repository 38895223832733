import {
	ChangeDetectionStrategy,
	Component,
} from "@angular/core";
import { User } from "@kunepro/common";
import { StoreFacade } from "@kunepro/ui-store";
import { RouteApp } from "@kunepro/ui-types";
import { Observable } from "rxjs";

@Component({
	selector:        "kun-app-header",
	templateUrl:     "./app-header.component.html",
	styleUrls:       [ "./app-header.component.scss" ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderComponent {
	public readonly isSignedIn$: Observable<boolean>           = this.storeFacadeService.auth.isSignedIn$;
	public readonly user$: Observable<User | undefined | null> = this.storeFacadeService.auth.user$;
	public readonly connected$: Observable<boolean>            = this.storeFacadeService.network.connected$;
	public readonly signInRoute                                = `/${ RouteApp.SignIn }`;

	constructor(private readonly storeFacadeService: StoreFacade) {}

	public onSignOut(): void {
		this.storeFacadeService.auth.logOff();
	}
}

import {
	PropsAuthAuthenticated,
	PropsFirebaseSignIn,
} from "@kunepro/ui-types";
import {
	createAction,
	props,
} from "@ngrx/store";

export const Authenticated = createAction(
	"[Auth] Authenticated",
	props<PropsAuthAuthenticated>(),
);

export const NotAuthenticated = createAction(
	"[Auth] Not authenticated",
);

export const AuthenticatedError = createAction(
	"[Auth] Authenticated error",
);

export const UserSignIn = createAction(
	"[Auth] User Sign In",
	props<PropsFirebaseSignIn>(),
);

export const SignOut = createAction(
	"[Auth] Sign Out",
);

export const SignOutSuccess = createAction(
	"[Auth] Sign Out Success",
);

export const SignOutError = createAction(
	"[Auth] Sign Out Error",
);

export const MLPLocations = [
	"Equestria",
	"Canterlot",
	"Ponyville",
	"Everfree Forest",
	"Saddle Lake",
	"White Tail Woods",
	"Froggy Bottom Bogg",
	"Cloudsdale",
	"Rambling Rock Ridge",
	"Appleloosa",
	"Manehattan",
	"Western Equestria",
	"Rockville",
	"Ghastly Gorge",
	"Dodge Junction",
	"Dragon Lands",
	"Frozen North",
	"Crystal Empire",
	"Crystal Mountains",
	"Yaket Mountain Range",
	"Winsome Falls",
	"Tenochtitlan Basin",
	"Rainbow Falls",
	"Fillydelphia",
	"Las Pegasus",
	"Applewood",
	"Baltimare",
	"Hollow Shades",
	"Well of Shade",
	"Galloping Gorge",
	"Foal Mountain",
	"Ponhenge",
	"Tall Tale",
	"Smokey Mountains",
	"Vanhoover",
	"Hill Top",
	"San Palomino Desert",
	"Badlands",
	"Dustmane Ridge",
	"Umberfoal",
	"Agaricus Forest",
	"Trial of Wind",
	"Site B",
	"Dodge Canyon",
	"Mane Six Campsite",
	"Site A &amp; Trial of Earth",
	"Trial of Fire",
	"Unicorn Mountain Range",
	"Breezie Village",
	"Flame Geyser Swamp",
	"Tartarus",
	"Neighagra Falls",
	"Our Town",
	"Sugarville",
	"Sire's Hollow",
	"Seaward Shoals",
	"Stratusburg",
	"Somnambula",
	"Hayseed Swamp",
	"Peaks of Peril",
	"Sweet Acorn Orchard",
	"Silver Stable Community",
	"Mt. Everhoof",
	"Hope Hollow",
	"Trottingham",
	"Macintosh Hills",
	"Canter Creek",
	"New Horseleans",
	"The Saddle Horn Peaks",
	"Salt Lick City",
	"Seaddle",
	"Chicoltgo",
	"Monacolt",
	"Forbidden Jungle",
	"Bales",
	"Mareidian",
	"Foaledo",
	"Horsolulu",
	"Trotland",
	"Mentioned regions",
	"Hoofington",
	"Saddle Arabia",
	"Mustangia",
	"Maretania",
	"Maretonia",
	"Hoof City",
	"Pinto Creek",
	"Flash Freeze Lake",
	"Whinnyapolis",
	"Puerto Caballo",
	"Plunder Cove",
	"Shire Lanka",
	"San Franciscolt",
	"Neigh York",
	"Mythica",
	"Trotsylvania",
	"Neigh Mexicolt",
	"Submerged Temples of Tehuti",
	"Yucatán Ponynsula",
	"Gallopinghost Islands",
	"Lost Temple of Tlatelolco",
	"Orshab",
	"Turley",
	"Ponderosa",
	"Big Thunder Mine",
	"Canterine",
	"Califoalnia",
	"Friendship Island",
	"Austailia",
	"Beyond Equestria",
	"Maretropolis",
	"Griffonstone",
	"Yakyakistan",
	"Chaosville",
	"Changeling Kingdom",
	"Bone Dry Desert",
	"Klugetown",
	"Mount Aris",
	"Hippogriffia",
	"Seaquestria",
	"Basalt Beach",
	"Land of the Scale Collectors",
	"Isles of Scaly",
	"Abyssinia",
	"Panthera",
	"Drungar",
	"Forgotten Hills",
	"Zorgarth",
	"Ornithia",
	"Mount Metazoa",
	"Bodies of water",
	"Celestial Sea",
	"Fillypine Sea",
	"Guto River",
	"Horseshoe Bay",
	"Luna Bay",
	"Luna Ocean",
	"Celestial objects",
	"The sun and moon",
	"Other celestial objects",
	"Human world",
	"Limbo",
	"Other dimensions",
	"World maps",
	"Notes",
	"References",
];
Object.freeze(MLPLocations);

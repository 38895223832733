export const MLPCrystalPoniesMares = [
	"Flurry Heart",
	"Golden Glitter",
	"Golden Hooves",
	"Golden Wheat",
	"Honeysparkle",
	"Lilac Luster",
	"Sapphire Joy",
	"Amethyst Maresbury",
	"Fleur De Verre",
	"Elbow Grease",
	"Autumn Gem",
	"Apple Cobbler",
	"Applejack",
	"Berryshine",
	"Amethyst Star",
	"Cloud Kicker",
	"Cherry Berry",
	"Eclair Crème",
	"Fine Line",
	"Fluttershy",
	"Gala Appleby",
	"Golden Harvest",
	"Lemon Hearts",
	"Minuette",
	"Orange Swirl",
	"Perfect Pie",
	"Pinkie Pie",
	"Princess Cadance",
	"Princess Celestia",
	"Princess Luna",
	"Rainbow Dash",
	"Rainbowshine",
	"Rarity",
	"Red Gala",
	"Rose",
	"Sassaflash",
	"Sea Swirl",
	"Sprinkle Medley",
	"Starlight Glimmer",
	"Twilight Sparkle",
	"Twinkleshine",
];

export const MLPCrystalPoniesStallions = [
	"Crystal Hoof",
	"Rooks Rampart",
	"Periwinkle Pace",
	"Crystal Beau",
	"Bright Smile",
	"Ivory Rook",
	"Lucky Clover",
	"Goldengrape",
	"Crusoe Palm",
	"Dr. Hooves",
	"Caramel",
	"Noteworthy",
	"Perfect Pace",
	"Shooting Star",
	"Shining Armor",
	"Royal Riff",
	"Sunburst",
];

export const MLPCrystalPonies = MLPCrystalPoniesMares.concat(MLPCrystalPoniesStallions);
Object.freeze(MLPCrystalPonies);
Object.freeze(MLPCrystalPoniesMares);
Object.freeze(MLPCrystalPoniesStallions);

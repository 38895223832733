import { Injectable } from "@angular/core";
import {
	CanLoad,
	Route,
	Router,
	UrlSegment,
	UrlTree,
} from "@angular/router";
import { StoreFacade } from "@kunepro/ui-store";
import { RouteApp } from "@kunepro/ui-types";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class IsSignedInGuard implements CanLoad {
	constructor(private readonly storeFacadeService: StoreFacade, private readonly router: Router) {}

	public canLoad(
		route: Route,
		segments: UrlSegment[],
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.storeFacadeService.auth.isSignedIn$
			.pipe(
				map((isSignedIn) => {
					if (!isSignedIn) {
						this.router.navigate([ RouteApp.SignIn ]);
					}

					return isSignedIn;
				}),
			);
	}

}

<button
	[matMenuTriggerFor]="menu"
	mat-icon-button
>
	<img
		(error)="onError($event)"
		[src]="user.photoURL"
		alt="User profile picture"
		class="user-photo"
		mat-card-avatar
	>
</button>
<mat-menu #menu="matMenu">
	<a
		mat-menu-item
		routerLink="profile"
	>
		<mat-icon>person</mat-icon>
		<span>Profile</span>
	</a>
	<mat-divider></mat-divider>
	<button
		(click)="onSignOut()"
		mat-menu-item
	>
		<mat-icon>close</mat-icon>
		<span>Sign out</span>
	</button>
</mat-menu>

import {
	ChangeDetectionStrategy,
	Component,
} from "@angular/core";

@Component({
	selector:        "kun-app-logo",
	templateUrl:     "./app-logo.component.html",
	styleUrls:       [ "./app-logo.component.scss" ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppLogoComponent {}

import { Injectable } from "@angular/core";
import { Email } from "@kunepro/common";
import {
	BehaviorSubject,
	Observable,
} from "rxjs";

@Injectable()
export class FormAuthService {
	private readonly _email$                  = new BehaviorSubject<Email>("");
	public readonly email$: Observable<Email> = this._email$.asObservable();

	public setEmail(email: Email): void {
		this._email$.next(email);
	}
}

import { Injectable } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanLoad,
	Route,
	Router,
	RouterStateSnapshot,
	UrlSegment,
	UrlTree,
} from "@angular/router";
import { URL } from "@kunepro/common";
import { StoreFacade } from "@kunepro/ui-store";
import { Observable } from "rxjs";
import {
	filter,
	map,
	switchMap,
	take,
} from "rxjs/operators";

@Injectable()
export class IsSignedOffGuard implements CanActivate, CanLoad {
	constructor(
		private readonly storeFacadeService: StoreFacade,
		private readonly router: Router,
	) {}

	public canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		return this.allowIfSignedOff$();
	}

	public canLoad(
		route: Route,
		segments: UrlSegment[],
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.allowIfSignedOff$();
	}

	private allowIfSignedOff$(): Observable<boolean | UrlTree> {
		return this.storeFacadeService.auth.user$
			.pipe(
				filter((user) => Boolean(user) || user === null),
				// KTC it is not possible to convert the following two switchMaps into a withLatestFrom operator
				// as there is a buggy interaction between withLatestFrom, Promises and IndexDB that was causing
				// the error: "EmptyError: no elements in sequence"
				switchMap(() => this.storeFacadeService.auth.isSignedIn$),
				switchMap((isSignedIn: boolean) => this.storeFacadeService.indexDB.auth.postAuthenticateReturnURL$
					.pipe(
						map((returnURl: URL | undefined): [ boolean, URL | undefined ] => [
							isSignedIn,
							returnURl,
						]),
					),
				),
				take(1),
				map(([ isSignedIn, returnURL ]: [ boolean, URL | undefined ]) =>
					isSignedIn
						? this.router.parseUrl(returnURL || "/")
						: true,
				),
			);
	}

}

import {
	FormControl,
	FormGroupDirective,
	NgForm,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { FELFormError } from "@kunepro/front-end-library";

export class ErrorStateMatcherPasswordConfirmation extends ErrorStateMatcher {
	public isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const passwordDoNotMatchError = form?.hasError(FELFormError.PasswordsDoNotMatch);

		// form?.submitted was deliberately omitted from the following check for design reasons.
		// It looks better if just the `password` field is errored until `passwordConfirmation` is touched
		return Boolean(control?.touched && (control?.errors || passwordDoNotMatchError));
	}
}

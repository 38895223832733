import {
	Dictionary,
	ObjectWithId,
} from "../..";

export function getListAndDictionaryFromMappable<K extends string | number, T extends ObjectWithId>(
	mappable: T[],
	currentDictionary: Dictionary<T> = {},
): [ K[], Dictionary<T> ] {
	const list = mappable.map((item) => item.id as K);

	const dictionary = mappable.reduce(
		(dict, item) => {
			dict[ item.id ] = {
				...item,
			};

			return dict;
		},
		{ ...currentDictionary },
	);

	return [
		list,
		dictionary,
	];
}

export const MLPPegasiMares = [
	"Fluttershy",
	"Rainbow Dash",
	"Blossomforth",
	"Clear Skies",
	"Daring Do",
	"Flitter",
	"Flutterholly",
	"Inky Rose",
	"Auntie Lofty",
	"Lucy Packard",
	"Mane Allgood",
	"Mrs. Shy",
	"Night Glider",
	"Rainbow Dash",
	"Snowdash",
	"Somnambula",
	"Songbird Serenade",
	"Stormy Flare",
	"Sunshower",
	"Windy Whistles",
	"Fleetfoot",
	"Spitfire",
	"Blaze",
	"High Winds",
	"Misty Fly",
	"Surprise",
	"Lilac Sky",
	"Spring Step",
	"Angel Wings",
	"Cloud Chaser",
	"Cloud Kicker",
	"Derpy",
	"Lightning Dust",
	"Meadow Flower",
	"Midnight Strike",
	"Orange Swirl",
	"Parasol",
	"Pizzelle",
	"Rolling Thunder",
	"Sassaflash",
	"Sprinkle Medley",
	"Sunshower Raindrops",
	"Vapor Trail",
	"White Lightning",
	"Wild Fire",
	"Blue Buck",
	"Helia",
	"High Spirits",
	"Honey Rays",
	"Jetstream",
	"Laurette",
	"Merry May",
	"Prim Posy",
	"Rainbowshine",
	"Silverspeed",
	"Strawberry Sunrise",
	"Sugar Twist",
	"Sunny Rays",
	"Tropical Storm",
	"Valley Trend",
	"Berryshine",
	"Cherry Berry",
	"Golden Harvest",
	"Luckette",
	"Lyra Heartstrings",
	"Mare Do Well",
	"Peachy Sweet",
	"Pinkie Pie",
	"Scootaloo",
	"Sea Swirl",
	"Shoeshine",
	"Strawberry Ice",
	"Twilight Sparkle",
	"Twinkleshine",
];

export const MLPPegasiStallions = [
	"Nightjar",
	"Jack Hammer",
	"Madden",
	"Eff Stop",
	"Big Shot",
	"Whitewash",
	"Starburst",
	"Starry Eyes",
	"Star Hunter",
	"Sky Stinger",
	"Stormfeather",
	"Warm Front",
	"Cerulean Skies",
	"Buddy",
	"Compass Star",
	"Fire Streak",
	"Zephyr Breeze",
	"Soarin",
	"Stellar Eclipse",
	"Spearhead",
	"Open Skies",
	"Mr. Shy",
	"royal guards",
	"Feather Flatterfly",
	"Cloud Chaser",
	"Crafty Crate",
	"Bow Hothoof",
	"Fluffy Clouds",
	"Flash Magnus",
	"Flash Sentry",
	"Hoops",
	"Prince Hisan",
	"Commander Ironhead",
	"Whiplash",
	"Tight Ship",
	"Fast Clip",
	"Mane Moon",
	"Silver Zoom",
	"Lightning Streak",
	"Thunderlane",
	"Wave Chill",
	"Wind Rider",
	"Bulk Biceps",
	"Rainbow Swoop",
	"Short Fuse",
	"Pound Cake",
	"Noteworthy",
	"Perfect Pace",
	"Globe Trotter",
	"Felix",
	"Dr. Hooves",
	"Emerald Green",
	"Lucky Clover",
	"Meadow Song",
	"Trusty Splendor",
	"royal guards",
	"Caramel",
	"Rainbow Blaze",
	"Prism Glider",
	"Steam Roller",
	"Stormbreaker",
	"Twilight Sky",
];

export const MLPPegasi = MLPPegasiMares.concat(MLPPegasiStallions);
Object.freeze(MLPPegasi);
Object.freeze(MLPPegasiMares);
Object.freeze(MLPPegasiStallions);

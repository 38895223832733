// noinspection TypeScriptPreferShortImport
import { CookieSameSite } from "../types/cookie-same-site";
import { Days } from "../types/days";

export class CookieAttributesBuilder {
	private domain                            = "";
	private expires                           = "";
	private httpOnly                          = "";
	private path                              = " Path=/;";
	private sameSite: string | CookieSameSite = "";
	private secure                            = "";

	public withDomain(domain: string) {
		this.domain = ` domain=${ domain };`;
		return this;
	}

	public expiresInDays(days: Days) {
		this.expires = ` Expires=${ new Date(Date.now() + 86400000 * days).toUTCString() };`;
		return this;
	}

	public withSameSite(value: CookieSameSite) {
		this.sameSite = ` SameSite=${ value };`;
		return this;
	}

	public withPath(path: string) {
		this.path = ` Path=${ path };`;
		return this;
	}

	public makeHttpOnly(value: boolean) {
		this.httpOnly = value ? " HttpOnly;" : "";
		return this;
	}

	public makeSecure(value: boolean) {
		this.secure = value ? " Secure;" : "";
		return this;
	}

	public build() {
		return this.expires + this.domain + this.path + this.sameSite + this.httpOnly + this.secure;
	}

}

import {
	ChangeDetectionStrategy,
	Component,
} from "@angular/core";
import { StoreFacade } from "@kunepro/ui-store";
import { MaterialTheme } from "@kunepro/ui-types";
import { Observable } from "rxjs";

@Component({
	selector:        "kun-root",
	templateUrl:     "./app.component.html",
	styleUrls:       [ "./app.component.scss" ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
	public readonly theme$: Observable<MaterialTheme> = this.storeFacade.material.theme$;

	constructor(private readonly storeFacade: StoreFacade) {}
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AppLogoComponent } from "./components/app-logo/app-logo.component";
import { IsSignedInGuard } from "./guards/is-signed-in.guard";
import { IsSignedOffGuard } from "./guards/is-signed-off.guard";

@NgModule({
	imports:      [
		CommonModule,
		RouterModule,
	],
	providers:    [
		IsSignedInGuard,
		IsSignedOffGuard,
	],
	declarations: [
		AppLogoComponent,
	],
	exports:      [
		AppLogoComponent,
	],
})
export class SharedModule {}

import { User } from "@kunepro/common";
import {
	StateApp,
	StateAuth,
	StateAuthMeta,
	StoreFeature,
} from "@kunepro/ui-types";
import {
	createFeatureSelector,
	createSelector,
} from "@ngrx/store";

const selectAuth = createFeatureSelector<StateApp, StateAuth>(StoreFeature.Auth);

export const selectUser = createSelector(
	selectAuth,
	(authState: StateAuth): User | null | undefined => authState.user,
);

export const selectMeta = createSelector(
	selectAuth,
	(authState: StateAuth): StateAuthMeta => authState.meta,
);

export const selectIsSignedIn = createSelector(
	selectMeta,
	(meta: StateAuthMeta): boolean => meta.isSignedIn,
);

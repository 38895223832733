export const MLPEarthPoniesMares = [
	"Applejack",
	"Pinkie Pie",
	"Aloe",
	"Blue Bobbin",
	"Butternut",
	"Charity Kindheart",
	"Cheerilee",
	"Cherry Jubilee",
	"Coco Pommel",
	"Coloratura",
	"Dr. Fauna",
	"The Headless Horse",
	"Aunt Holiday",
	"Hoofer Steps",
	"Junebug",
	"Lighthoof",
	"Lotus Blossom",
	"Mage Meadowbrook",
	"Mane-iac",
	"Mare Do Well",
	"Maud Pie",
	"Mayor Mare",
	"Mrs. Cup Cake",
	"Ms. Harshwhinny",
	"Ms. Peachbottom",
	"Nurse Redheart",
	"Orange Slice",
	"Photo Finish",
	"Prim Hemline",
	"Professor Fossil",
	"Sapphire Shores",
	"Shimmy Shake",
	"Spoiled Rich",
	"Suri Polomare",
	"Torch Song",
	"Trapeze Star",
	"Tree Hugger",
	"Mrs. Trotsworth",
	"Wrangler",
	"Cloudy Quartz",
	"Limestone Pie",
	"Marble Pie",
	"Pear Butter",
	"Apple Brown Betty",
	"Apple Bumpkin",
	"Apple Cider",
	"Apple Cobbler",
	"Apple Dumpling",
	"Apple Fritter",
	"Apple Honey",
	"Apple Leaves",
	"Apple Munchies",
	"Aunt Orange",
	"Candy Apples",
	"Caramel Apple",
	"Florina Tart",
	"Gala Appleby",
	"Jonagold",
	"Lavender Fritter",
	"Peachy Sweet",
	"Perfect Pie",
	"Red Gala",
	"Beauty Brass",
	"Berry Dreams",
	"Berry Preppy",
	"Berryshine",
	"Big Wig",
	"Blueberry Curls",
	"Charged Up",
	"Cherry Berry",
	"Daisy",
	"Dane Tee Dove",
	"Deputy Copper",
	"Eclair Crème",
	"Fiddly Twang",
	"Golden Harvest",
	"Grape Delight",
	"Green Jewel",
	"Hinny of the Hills",
	"Janine Manewitz",
	"Joan Pommelway",
	"Lady Gaval",
	"Lavender Bloom",
	"Lilac Links",
	"Lily Valley",
	"Long Shot",
	"Luckette",
	"Lucky Star",
	"Lyrica Lilac",
	"Manely Gold",
	"Mare E. Belle",
	"Mare E. Lynn",
	"Midnight Fun",
	"Minty",
	"Mrs. Paleo",
	"Nurse Snowheart",
	"Octavia Melody",
	"Pacific Glow",
	"Pegasus Olsen",
	"Picture Frame",
	"Powder Rouge",
	"Pretty Vision",
	"Purple Wave",
	"Pursey Pink",
	"Raspberry Beret",
	"Raven Inkwell",
	"Rose",
	"Roxie Rave",
	"Ruby Splash",
	"Screwball",
	"Screwy",
	"Shoeshine",
	"Silver Berry",
	"Silver Frames",
	"Snappy Scoop",
	"Soigne Folio",
	"Spring Forward",
	"Stella Lashes",
	"Strawberry Ice",
	"Sunny Smiles",
	"Sweetie Drops",
	"Symphony Song",
	"Vidala Swoon",
	"Amethyst Star",
	"Apple Bloom",
	"Apple Rose",
	"Auntie Applesauce",
	"Cloud Kicker",
	"Derpy",
	"Diamond Mint",
	"Diamond Tiara",
	"Fine Line",
	"Fluttershy",
	"Goldie Delicious",
	"Granny Smith",
	"Helia",
	"Juniper Montage",
	"Lemon Hearts",
	"Lemony Gem",
	"Lyra Heartstrings",
	"Merry May",
	"Minuette",
	"Orange Swirl",
	"Parasol",
	"Pearly Stitch",
	"Press Release",
	"Rainbow Dash",
	"Rarity",
	"Sassaflash",
	"Scootaloo",
	"Sea Swirl",
	"Silver Spoon",
	"Sprinkle Medley",
	"Starlight Glimmer",
	"Strawberry Sunrise",
	"Sunshower Raindrops",
	"Swan Song",
	"Twilight Sparkle",
	"Twinkleshine",
	"Twist",
	"White Lightning",
	"Yona",
];

export const MLPEarthPoniesStallions = [
	"Big Bucks",
	"Big Daddy McColt",
	"Big McIntosh",
	"Braeburn",
	"Buried Lede",
	"Burnt Oak",
	"Cattail",
	"Chargrill Breadwinner",
	"Cheese Sandwich",
	"Code Red",
	"Double Diamond",
	"Dr. Caballeron",
	"Dr. Horse",
	"Feather Bangs",
	"Filthy Rich",
	"Gizmo",
	"Gladmane",
	"Grub Hooffield",
	"Hard Hat",
	"Hoity Toity",
	"Mayor Baltimare",
	"Mayor Cream Cheese",
	"Mr. Carrot Cake",
	"Mr. Stripes",
	"Mudbriar",
	"Oak Nut",
	"Piles McColt",
	"Quibble Pants",
	"Rockhoof",
	"royal guards",
	"Rusty Bucket",
	"Sans Smirk",
	"Sheriff Silverstar",
	"Silver Shill",
	"Snap Shutter",
	"Starstreak",
	"Stinky Bottom",
	"Svengallop",
	"Toe-Tapper",
	"Trouble Shoes",
	"Twisty Pop",
	"Bright Mac",
	"Igneous Rock Pie",
	"Stinkin' Rich",
	"Apple Cinnamon",
	"Apple Split",
	"Golden Delicious",
	"Half Baked Apple",
	"Hayseed Turnip Truck",
	"Red Delicious",
	"Uncle Orange",
	"Wensley",
	"Ace Point",
	"B. Sharp",
	"Barber Groomsby",
	"BeauDe Mane",
	"Biff",
	"Big Top",
	"Bill Neigh",
	"Mr. Breezy",
	"Business Savvy",
	"Caramel",
	"Cherry Fizzy",
	"Count Caesar",
	"Cratus",
	"Crusoe Palm",
	"Dance Fever",
	"Davenport",
	"Diamond Cutter",
	"Don Neigh",
	"Dr. Hooves",
	"Emerald Green",
	"Felix",
	"Frederick Horseshoepin",
	"Full Steam",
	"Ginger Beard",
	"Globe Trotter",
	"Goldengrape",
	"Hughbert Jellius",
	"Jeff Letrotski",
	"Jesús Pezuña",
	"Karat",
	"Leadwing",
	"Levon Song",
	"Lucky Clover",
	"Meadow Song",
	"Mr. Paleo",
	"Neigh Sayer",
	"Night Watch",
	"Noteworthy",
	"On Stage",
	"Parcel Post",
	"Parish Nandermane",
	"Perfect Pace",
	"Roger Silvermane",
	"Rogue",
	"Rough Tumble",
	"Royal Riff",
	"Savoir Fare",
	"Sealed Scroll",
	"Shooting Star",
	"Star Spur",
	"Swanky Hank",
	"Tall Order",
	"Tall Tale",
	"Twilight Sky",
	"Upper East Stride",
	"Walter",
	"Withers",
	"Wolfgang Canter",
	"Apple Strudel",
	"Bulk Biceps",
	"Comet Tail",
	"Crafty Crate",
	"Flash Sentry",
	"Grand Pear",
	"Jack Hammer",
	"Mane Moon",
	"Party Favor",
	"Rainbow Swoop",
	"Rare Find",
	"Sand Trap",
	"Sandbar",
	"Snappy Scoop",
	"Star Hunter",
	"Starburst",
	"Steam Roller",
	"Stellar Eclipse",
	"Stormfeather",
	"Thunderlane",
	"Mr. Waddle",
	"Warm Front",
	"Whiplash",
];

export const MLPEarthPonies = MLPEarthPoniesMares.concat(MLPEarthPoniesStallions);
Object.freeze(MLPEarthPonies);
Object.freeze(MLPEarthPoniesMares);
Object.freeze(MLPEarthPoniesStallions);

export const MockedNouns: string[] = [
	"tree",
	"piano",
	"pony",
	"cat",
	"snowboard",
	"book",
	"library",
	"marshmellow",
	"mare",
	"browser",
	"mario",
];

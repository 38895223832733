import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { PropsUserGetSuccess } from "@kunepro/ui-types";
import { Observable } from "rxjs";

@Injectable()
export class UserService {
	constructor(private readonly db: AngularFireDatabase) {}

	public getUser$(id: string): Observable<PropsUserGetSuccess | null> {
		return this.db.object<PropsUserGetSuccess>(`/users/${ id }`).valueChanges();
	}
}

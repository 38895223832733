import {
	PropsUserGet,
	PropsUserGetSuccess,
} from "@kunepro/ui-types";
import {
	createAction,
	props,
} from "@ngrx/store";

export const Get = createAction(
	"[User] Log in",
	props<PropsUserGet>(),
);

export const GetSuccess = createAction(
	"[User] Get success",
	props<PropsUserGetSuccess>(),
);

export const GetNotFound = createAction(
	"[User] Get success",
);

export const GetError = createAction(
	"[User] Get error",
);


export const UserActions = {
	Get,
	GetSuccess,
	GetNotFound,
	GetError,
};

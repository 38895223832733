import { StateApp } from "@kunepro/ui-types";
import {
	ActionReducer,
	MetaReducer,
} from "@ngrx/store";

import { storeLogger } from "ngrx-store-logger";
import { environment } from "../../environments/environment";

export function logger(reducer: ActionReducer<StateApp>) {
	return storeLogger({
		collapsed: true,
		timestamp: false,
		filter:    {
			blacklist: [
				"@ngrx/effects/init",
				"@ngrx/store/update-reducers",
				"@ngrx/store-devtools/recompute",
				// "@ngrx/router-store/request",
				// "@ngrx/router-store/navigation",
				// "@ngrx/router-store/navigated",
			],
		},
	})(
		reducer,
	);
}

export const metaReducers: MetaReducer<StateApp>[] = !environment.production
	? [ logger ]
	: [];

import {
	APP_INITIALIZER,
	Inject,
	NgModule,
} from "@angular/core";
import { WINDOW } from "@kunepro/front-end-library";
import { EntityDataModule } from "@ngrx/data";
import { EffectsModule } from "@ngrx/effects";
import {
	RouterState,
	StoreRouterConnectingModule,
} from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../../environments/environment";
import { AuthEffects } from "./auth/auth.effects";
import {
	IndexDBService,
	initialiseIDB,
} from "./index-db/index-db.service";
import { MaterialService } from "./material/material.service";
import { metaReducers } from "./meta-reducers";
import { ConnectivityService } from "./network/connectivity.service";
import { reducers } from "./reducers";
import { CustomRouterSerialiser } from "./router/custom-router-serialiser";
import { UserEffects } from "./user/user.effects";
import { UserService } from "./user/user.service";

const simpleId = (Math.random() * 1000).toFixed(0);

const storeEffects = [
	AuthEffects,
	UserEffects,
];

export function createDevToolsConfig() {
	let instanceName = "Kunepro Store DevTools";
	try {
		if (window) {
			instanceName = window.location.host;
		}
	} catch (e) {}

	return {
		name:    `${ instanceName } - ${ simpleId }`,
		logOnly: environment.production,
	};
}

@NgModule({
	imports:   [
		StoreModule.forRoot(
			reducers,
			{
				metaReducers,
				runtimeChecks: {
					strictStateImmutability:     true,
					strictActionImmutability:    true,
					strictStateSerializability:  true,
					strictActionSerializability: true,
				},
			},
		),
		EffectsModule.forRoot(storeEffects),
		StoreDevtoolsModule.instrument(createDevToolsConfig),
		StoreRouterConnectingModule.forRoot({
			serializer:  CustomRouterSerialiser,
			routerState: RouterState.Minimal,
		}),
		EntityDataModule,
	],
	providers: [
		ConnectivityService,
		MaterialService,
		UserService,
		IndexDBService,
		{
			provide:    APP_INITIALIZER,
			useFactory: initialiseIDB,
			multi:      true,
			deps:       [
				IndexDBService,
			],
		},
	],
})
export class AppStoreModule {
	constructor(@Inject(WINDOW) public window: Window) {
		window.name = simpleId;
	}
}

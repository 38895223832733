// Avatars
export * from "./lib/avatars/mocked-avatar-big-mac";
export * from "./lib/avatars/mocked-avatar-cadenza-mi-amore";
export * from "./lib/avatars/mocked-avatar-derpy-hooves";
export * from "./lib/avatars/mocked-avatar-discord";
export * from "./lib/avatars/mocked-avatar-maud-pie";
export * from "./lib/avatars/mocked-avatar-octavia-melody";
export * from "./lib/avatars/mocked-avatar-pinkamena-pie";
export * from "./lib/avatars/mocked-avatar-pinky-pie";
export * from "./lib/avatars/mocked-avatar-starlight-glimmer";
export * from "./lib/avatars/mocked-avatar-trixie-lulamoon";
export * from "./lib/avatars/mocked-avatar-twilight-sparkle";
export * from "./lib/avatars/mocked-avatar-vinyl-scratch";

// Mocks
export * from "./lib/mlp-alicorns";
export * from "./lib/mlp-crystal-ponies";
export * from "./lib/mlp-earth-ponies";
export * from "./lib/mlp-kirin";
export * from "./lib/mlp-locations";
export * from "./lib/mlp-pegasi";
export * from "./lib/mlp-unicorns";
export * from "./lib/mocked-nouns";
export * from "./lib/mocked-verbs";

// Modules
export * from "./lib/modules/translate-mock.module";

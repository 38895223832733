import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from "@angular/core";
import { User } from "@kunepro/common";

@Component({
	selector:        "kun-app-header-profile-card-view",
	templateUrl:     "./app-header-profile-card-view.component.html",
	styleUrls:       [ "./app-header-profile-card-view.component.scss" ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderProfileCardViewComponent {
	@Input() public readonly user!: User;
	@Output() public readonly signOut = new EventEmitter<void>();

	public onSignOut(): void {
		this.signOut.emit();
	}

	public onError(a: Event): void {
		(a.target as HTMLImageElement).src = "../../../../../assets/images/placeholder_avatar.png";
	}
}

import {
	AngularFireAction,
	DatabaseSnapshot,
} from "@angular/fire/database";
// noinspection ES6PreferShortImport
import { PropsFirebase } from "../types/props-firebase";
// noinspection ES6PreferShortImport
import { RealtimeDbActionType } from "../types/realtime-db-action-type";

export class RealtimeDBBuffer<T> {
	public readonly added: PropsFirebase<T>[]   = [];
	public readonly changed: PropsFirebase<T>[] = [];
	public readonly removed: PropsFirebase<T>[] = [];
	public readonly moved: PropsFirebase<T>[]   = [];

	public static build<T>(actions: AngularFireAction<DatabaseSnapshot<T>>[]): RealtimeDBBuffer<T> {
		return actions.reduce(
			(buffer, action) => {
				switch (action.type) {
					case RealtimeDbActionType.Added:
						buffer.added.push(RealtimeDBBuffer.wrap<T>(action));
						break;
					case RealtimeDbActionType.Changed:
						buffer.changed.push(RealtimeDBBuffer.wrap<T>(action));
						break;
					case RealtimeDbActionType.Removed:
						buffer.removed.push(RealtimeDBBuffer.wrap<T>(action));
						break;
					default:
						buffer.moved.push(RealtimeDBBuffer.wrap<T>(action));
						break;
				}

				return buffer;
			},
			new RealtimeDBBuffer<T>(),
		);
	}

	private static wrap<T>(action: AngularFireAction<DatabaseSnapshot<T>>): PropsFirebase<T> {
		return {
			id:   action.key as string,
			data: action.payload.val() as T,
		};
	}
}

import {
	MockedNouns,
	MockedVerbs,
} from "@kunepro/mocks";
import { times } from "ramda";
// noinspection ES6PreferShortImport
import { DateISO } from "../types/date-iso";
// noinspection ES6PreferShortImport
import { DateUNIX } from "../types/date-unix";
// noinspection ES6PreferShortImport
import { Days } from "../types/days";
// noinspection ES6PreferShortImport
import { Email } from "../types/email";
// noinspection ES6PreferShortImport
import { Hours } from "../types/hours";
// noinspection ES6PreferShortImport
import { Milliseconds } from "../types/milliseconds";
// noinspection ES6PreferShortImport
import { Minutes } from "../types/minutes";
// noinspection ES6PreferShortImport
import { Seconds } from "../types/seconds";

export class Generate {
	public static unixToISO(date: DateUNIX): DateISO {
		return new Date(date).toISOString();
	}

	public static timeReversingTimestamps(quantity: number): DateUNIX[] {
		let time = Date.now();

		return times(
			() => {
				time -= (Generate.successRollByPercentage(15)
					? Generate.hoursInMilli(Generate.randomNumberInRange(
						1,
						72,
					))
					: 0)
					+ Generate.minutesInMilli(Generate.randomNumberInRange(
						0,
						30,
					))
					+ Generate.randomNumberInRange(
						0,
						60,
					) * 1000;

				return time;
			},
			quantity,
		);
	}

	public static daysInMilli(days: Days) {
		return 86400000 * days;
	}

	public static hoursInMilli(hours: Hours) {
		return 3600000 * hours;
	}

	public static minutesInMilli(minutes: Minutes) {
		return 60000 * minutes;
	}

	public static getToday(): DateUNIX {
		const today = new Date();
		today.setMilliseconds(0);
		today.setSeconds(0);
		today.setMinutes(0);
		today.setHours(0);

		return today.getTime();
	}

	public static randomDurationByRange(
		min: Seconds,
		max: Seconds,
	): Milliseconds {
		return Math.floor(((Math.random() * (max - min)) + min) * 1000);
	}

	public static randomEmail(): Email {
		const name = this.randomString(this.randomNumberInRange(
			2,
			20,
		));
		const host = this.randomString(this.randomNumberInRange(
			2,
			20,
		));

		return `${ name }@${ host }.com`;
	}

	public static randomNumberInRange(
		min: number,
		max: number,
	): number {
		return Math.floor(((Math.random() * (1 + max - min)) + min));
	}

	// Example 25 for 25%
	public static successRollByPercentage(percentage: number): boolean {
		return Math.round(Math.random() * 100) <= percentage;
	}

	public static randomString(length: number): string {
		const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

		return Array.from(Array(length)).reduce(
			(result) => result + chars[ Math.floor(Math.random() * chars.length) ],
			"",
		);
	}

	public static randomStringQuick(): string {
		return Math.random().toString(36).substring(
			2,
			15,
		);
	}

	public static randomNumber(length: number): number {
		let value: number = Math.floor(Math.random() * Math.pow(
			10,
			length,
		));

		if (String(value).length < length) {
			value = Number("" + value + Generate.randomNumber(length - String(value).length));
		}

		return value;
	}

	public static randomAction(): string {
		return `${ MockedVerbs[ Generate.randomNumberInRange(
			0,
			MockedVerbs.length - 1,
		) ] } ${ MockedNouns[ Generate.randomNumberInRange(
			0,
			MockedNouns.length - 1,
		) ] }`;
	}
}

import {
	AdditionalUserInfo,
	AuthCredential,
	AuthOperationType,
	Email,
	FacebookUserId,
	GoogleUserId,
	ProviderId,
	ProviderProfile,
	URL,
	User,
	UserCredential,
	UserInfo,
} from "@kunepro/common";
import * as firebase from "firebase";

interface ProviderProfileGoogle {
	readonly email: Email;
	readonly family_name: string;
	readonly given_name: string;
	readonly granted_scopes: string;
	readonly id: GoogleUserId;
	readonly locale: string;
	readonly name: string;
	readonly picture: URL;
	readonly verified_email: boolean;
}

interface ProviderProfileFacebook {
	readonly email: Email;
	readonly first_name: string;
	readonly granted_scopes: string[];
	readonly id: FacebookUserId;
	readonly last_name: string;
	readonly name: string;
	readonly picture: {
		readonly data: {
			readonly height: number;
			readonly is_silhouette: boolean;
			readonly url: URL;
			readonly width: number;
		};
	};
}

export class FirebaseUserSerialiser {

	public userCredentialFromFirebaseUserCredential(userCredential: firebase.auth.UserCredential): UserCredential {
		const user: User | null                                         = userCredential.user
			&& this.userFromFirebaseUser(userCredential.user);
		const additionalUserInfo: AdditionalUserInfo | undefined | null = userCredential.additionalUserInfo
			&& this.additionalUserInfoFromFirebaseAdditionalUserInfo(userCredential.additionalUserInfo);
		const credential: AuthCredential | null                         = userCredential.credential
			&& this.authCredentialFromFirebaseAuthCredential(userCredential.credential);

		return {
			user,
			credential,
			operationType: userCredential.operationType as AuthOperationType,
			additionalUserInfo,
		};
	}

	public userFromFirebaseUser(user: firebase.User): User {
		return {
			userId:        user.uid,
			displayName:   user.displayName,
			email:         user.email,
			emailVerified: user.emailVerified,
			isAnonymous:   user.isAnonymous,
			createdAt:     user.metadata.creationTime ? Date.parse(user.metadata.creationTime) : undefined,
			lastLoginAt:   user.metadata.lastSignInTime ? Date.parse(user.metadata.lastSignInTime) : undefined,
			phoneNumber:   user.phoneNumber,
			photoURL:      user.photoURL,
			providerData:  user.providerData.map((data) => data && this.userInfoFromFirebaseUserInfo(data)),
		};
	}

	public userInfoFromFirebaseUserInfo(providerData: firebase.UserInfo): UserInfo {
		return {
			userId:      providerData.uid,
			displayName: providerData.displayName,
			email:       providerData.email,
			phoneNumber: providerData.phoneNumber,
			photoURL:    providerData.photoURL,
			providerId:  providerData.providerId as ProviderId,
		};
	}

	public additionalUserInfoFromFirebaseAdditionalUserInfo(info: firebase.auth.AdditionalUserInfo): AdditionalUserInfo {
		const profile: ProviderProfile | null = this.profileFromProvider(
			info.providerId as ProviderId,
			info.profile as ProviderProfileGoogle | ProviderProfileFacebook | null,
		);

		return {
			isNewUser:  info.isNewUser,
			providerId: info.providerId as ProviderId,
			profile,
			username:   info.username,
		};
	}

	public profileFromProvider(
		providerId: ProviderId,
		profile: ProviderProfileGoogle | ProviderProfileFacebook | null,
	): ProviderProfile | null {
		switch (providerId) {
			case ProviderId.Google:
				return this.providerProfileFromGoogleProviderProfile(profile as ProviderProfileGoogle);
			case ProviderId.Facebook:
				return this.providerProfileFromFacebookProviderProfile(profile as ProviderProfileFacebook);
			// case ProviderId.Password: // KTC Password never has a ProviderProfile.
			default:
				return null;
		}
	}

	public providerProfileFromGoogleProviderProfile(profile: ProviderProfileGoogle): ProviderProfile {
		return {
			email:               profile.email,
			familyName:          profile.family_name,
			givenName:           profile.given_name,
			grantedScopesGoogle: profile.granted_scopes,
			googleId:            profile.id,
			locale:              profile.locale,
			name:                profile.name,
			pictureGoogle:       profile.picture,
			verifiedEmail:       profile.verified_email,
		};
	}

	public providerProfileFromFacebookProviderProfile(profile: ProviderProfileFacebook): ProviderProfile {
		return {
			email:                 profile.email,
			familyName:            profile.last_name,
			givenName:             profile.first_name,
			grantedScopesFacebook: profile.granted_scopes,
			facebookId:            profile.id,
			name:                  profile.name,
			pictureFacebook:       profile.picture.data.url,
		};
	}

	public authCredentialFromFirebaseAuthCredential(credential: firebase.auth.AuthCredential): AuthCredential {
		return credential && {
			providerId:   credential.providerId as ProviderId,
			signInMethod: credential.signInMethod as ProviderId,
		};
	}
}

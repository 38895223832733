import {
	ErrorHandler,
	Injectable,
	Provider,
} from "@angular/core";
import {
	ErrorCodeFirebase,
	isFirebaseError,
	isZoneError,
	ZoneError,
} from "@kunepro/common";
import { Action } from "@ngrx/store";
import * as Sentry from "@sentry/browser";
import { FirebaseError } from "firebase";
import {
	of,
	OperatorFunction,
} from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";

interface PropsFirebaseError {
	readonly code: ErrorCodeFirebase | string;
}

Sentry.init({
	dsn: "https://d07dcddd3186443ba1483ac543e99066@sentry.io/1194650",
});

interface CustomError extends Error {
	originalError?: Error;
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
	public handleError(error: CustomError): void {
		Sentry.captureException(error.originalError || error);
		throw error;
	}
}

export function handleEffectError$<T>(action: Action): OperatorFunction<T, T | Action> {
	return catchError((error: Error | ZoneError) => {
			logToSentry(error);

			return of(action);
		},
	);
}

// tslint:disable-next-line:no-any
export function handleEffectErrorWithFirebaseCode$<T>(action: (g: any) => Action): OperatorFunction<T, T | Action> {
	return catchError((error: FirebaseError | ZoneError | Error) => {
			const code: PropsFirebaseError = isFirebaseError(error)
				? { code: error.code }
				: { code: "unknown" };

			logToSentry(error);

			return of(action(code));
		},
	);
}

// tslint:disable-next-line:no-any
export function logToSentry(error: any): void {
	const err = isZoneError(error)
		? error.originalError
		: error;

	if (environment.production) {
		Sentry.captureException(err);
	} else {
		// tslint:disable-next-line:no-console
		console.log(err);
	}
}

export function sentryErrorHandlerFactory(): SentryErrorHandler | ErrorHandler {
	return environment.production ? new SentryErrorHandler() : new ErrorHandler();
}

export const SentryProvider: Provider = {
	provide:    ErrorHandler,
	useFactory: sentryErrorHandlerFactory,
};

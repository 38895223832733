export enum ErrorCodeFirebase {
	AuthAccountExistsWithDifferentCredential = "auth/account-exists-with-different-credential",
	AuthEmailAlreadyInUse                    = "auth/email-already-in-use",
	AuthCancelledPopUpRequest                = "auth/cancelled-popup-request",
	AuthExpiredActionCode                    = "auth/expired-action-code",
	AuthInvalidActionCode                    = "auth/invalid-action-code",
	AuthNetworkRequestFailed                 = "auth/network-request-failed",
	AuthOperationNotAllowed                  = "auth/operation-not-allowed",
	AuthPopUpBlocked                         = "auth/popup-blocked",
	AuthPopUpClosedByUser                    = "auth/popup-closed-by-user",
	AuthTooManyRequests                      = "auth/too-many-requests",
	AuthUserDisabled                         = "auth/user-disabled",
	AuthUserNotFound                         = "auth/user-not-found",
	AuthWrongPassword                        = "auth/wrong-password",
}

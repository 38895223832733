import { RouterStateSnapshot } from "@angular/router";
import { getFragmentsFromUrl } from "@kunepro/common";
import {
	QueryParamsKunepro,
	StateRouter,
} from "@kunepro/ui-types";
import { RouterStateSerializer } from "@ngrx/router-store";

export class CustomRouterSerialiser implements RouterStateSerializer<StateRouter> {
	serialize(routerState: RouterStateSnapshot): StateRouter {
		let route = routerState.root;

		while (route.firstChild) {
			route = route.firstChild;
		}

		const url                 = routerState.url;
		const queryParams         = routerState.root.queryParams as QueryParamsKunepro;
		const params              = route.params;
		const fragments: string[] = getFragmentsFromUrl(url);

		// tslint:disable-next-line:no-any
		// const sidePanelOutlet  = (routerState.root as any)._urlSegment.children.sidePanel;
		// const auxiliaryOutlets = {
		// 	sidePanel: sidePanelOutlet && sidePanelOutlet.segments[ 0 ].path,
		// };

		return {
			url,
			params,
			queryParams,
			fragments,
			// auxiliaryOutlets,
		};
	}
}


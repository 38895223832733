import { isPlatformBrowser } from "@angular/common";
import {
	ClassProvider,
	FactoryProvider,
	Injectable,
	InjectionToken,
	PLATFORM_ID,
	Provider,
} from "@angular/core";

export const WINDOW = new InjectionToken("WindowToken");

export abstract class WindowRef {
	get nativeWindow(): Window | object {
		throw new Error("Not implemented.");
	}
}

@Injectable()
export class BrowserWindowRef extends WindowRef {
	get nativeWindow(): Window | object {
		return window;
	}
}

export function windowFactory(
	browserWindowRef: BrowserWindowRef,
	platformId: object,
): Window | object {
	return isPlatformBrowser(platformId) ? browserWindowRef.nativeWindow : {};
}

const BrowserWindowProvider: ClassProvider = {
	provide:  WindowRef,
	useClass: BrowserWindowRef,
};

const WindowProvider: FactoryProvider = {
	provide:    WINDOW,
	useFactory: windowFactory,
	deps:       [
		WindowRef,
		PLATFORM_ID,
	],
};

export const WindowProviders: Provider[] = [
	BrowserWindowProvider,
	WindowProvider,
];

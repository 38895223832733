export class TestJWTRSAKeyPair {
	public static readonly privateKey = `-----BEGIN RSA PRIVATE KEY-----
MIIEogIBAAKCAQEAvgtQZn5EIS306yqQ5WwyPCAvMV4R0TgdwLvHnob0Fwl5JsjL
DF6Nl6+BNpzWp4QUqtRThb9OFDnob5Q5MWlQ+PSaQ7r7QAYstftaalU0ZclNqGWI
6a9p1gGsDaJ+LSpW1HCkGxQj5I2e/QPfSz00l2VDS1fbUBOhBe1GyJMIhyQOmCmY
a2Vp9xAz/A0B2nYO3Z4y4x6VLfZ3pa+8yOKoMievH9q1Plkha4OLgcg8uDDA4W+C
o2Dzj0AsWgAWO6aQpMSVqZi92v5PnwH5qsiGoezIwlVRi1uGB7uUu10FG29CLv8w
FkPVJNXvom50HQL75Z3J2cmOsQkvijMcrT/vIwIDAQABAoIBAFmOs2DZd/m60oRQ
FuBG0JWNBTBaiEL0EvFUjImkRzrj9/fS//zgQhGNX3MBDhb7I1ItpP6OXJkjgOm0
DA/A9auceWYZo3VUc5V+0ejyJjMhXjtRPegCBfoX56C1Roi6Pxj1zCAGqbN3Gcla
HcwzF6mQytmimXNvDJ7iZkz3HTmxcDrUa2bGf+41umMXot6BtUBul6u1lhlVml+L
3nQeGAAcISpzlXzmyNODMcR8MfDCyxRxRuUKpTCHO2ahX5cXyNSkeXg1IaerPlWE
ox3s9nqiDkqEXeTryXPJkjMV09vwIP/3Vvqw/sFG8gihEzy5C+ALo2XI+R2VXFEL
xOGk06ECgYEA5HauZULh2TS8CRFeY09OTYdJuUd6WJv6SZHxbgZni3QR0fP1XPSA
1/fkrBV4gICBMzniPXem2raVJ25SWEm+sG24w2wZm/g/6Fq9oLAA8J12EmmiD+AJ
WLwF/wpm5skp/9Z5aXHWjdpAIUkH3Wc70XENehj9d3mV+OwjhRDsfa8CgYEA1PMw
i+QpuPjKdud7edrbutIjEj2Zi5c562PllNGx8609rJN4U87unrXu3kxmGHvQ4HPC
OfHbPCGsvnKvUKhnQ1lRqgQU2dSUHMqPAqcGrdw1HxUjhTssIIA8rie6JbvXI6Rq
Id8lnZyREgZZ/YvMuyxz5rO7BjgosgFdB4jh1s0CgYB0rHuBsTdf0sOYqesXMoAd
rdKjsXI04GtguQygENs+Z6dWHo2WzDMkmC+LBmgE0BurSotUss9ecNIZUgjxl6+v
sEkkXAd0FMKmafjfoCLKvs92tcWS7+o2C8fQKMLGZ8NwKVrV09IrnWMU3JQMqNTu
sBqe2TZQlqJVflrhcPQldwKBgFT51l18ZsnRPbnFFZjCpPwf7q7QtfzmE87CxlJk
e0Zp0zqSfmmzIbVjlDZQ9ky+29M1XkQRJxMPvQcibKMbEFnnwpa9mjRyh8gVbIv6
asfmH6yPmuUHf8xYX95/K+Dy3B709QUfLrt+RsWSk0aCCuiGDJn7Dp6kuliOArmG
/dfhAoGAMfIxiAHdXrm8BQFBK8ia1JqEC9aiQN9BG+rqzOsNXJm445jz3ZiBD5r4
eSTJMugGjyCjxddkouYjElOtnDoMeHtaFz5tNl/az79penTBTIjSn1Qo3LWUQkQ1
9eCxSSR6mPSDBOWIC4sL+fwwqX1uYw4BC4ZGrJyvnkqGdFphYYo=
-----END RSA PRIVATE KEY-----`;
	public static readonly publicKey  = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvgtQZn5EIS306yqQ5Wwy
PCAvMV4R0TgdwLvHnob0Fwl5JsjLDF6Nl6+BNpzWp4QUqtRThb9OFDnob5Q5MWlQ
+PSaQ7r7QAYstftaalU0ZclNqGWI6a9p1gGsDaJ+LSpW1HCkGxQj5I2e/QPfSz00
l2VDS1fbUBOhBe1GyJMIhyQOmCmYa2Vp9xAz/A0B2nYO3Z4y4x6VLfZ3pa+8yOKo
MievH9q1Plkha4OLgcg8uDDA4W+Co2Dzj0AsWgAWO6aQpMSVqZi92v5PnwH5qsiG
oezIwlVRi1uGB7uUu10FG29CLv8wFkPVJNXvom50HQL75Z3J2cmOsQkvijMcrT/v
IwIDAQAB
-----END PUBLIC KEY-----`;
}

import { StateApp } from "@kunepro/ui-types";
import { routerReducer } from "@ngrx/router-store";
import { ActionReducerMap } from "@ngrx/store";
import { authReducer } from "./auth/auth.reducer";
import { userReducer } from "./user/user.reducer";

export const reducers: ActionReducerMap<StateApp> = {
	auth:   authReducer,
	router: routerReducer,
	user:   userReducer,
};
